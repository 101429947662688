import React from 'react';
import {
  Stack,
  Typography,
  Divider,
  Button,
  List,
  ListItem,
} from '@mui/material';

const TwilioHelp = () => (
  <Stack
    justifyContent="space-around"
    alignItems="center"
    style={{ minHeight: '80vh', padding: 10 }}
  >
    <Typography variant="h4">This app requires a Twilio Account</Typography>
    <Divider style={{ width: '100%', marginTop: 25 }} />
    <Typography variant="h5">What's Twilio?</Typography>
    <Typography variant="body1">
      Twilio is a service that sends SMS messages on a user's behalf. It's free
      to sign up and cost effective to use.
    </Typography>
    <Button variant="contained" href="https://www.twilio.com" target="_blank">
      Sign up for Twilio
    </Button>
    <Divider style={{ width: '100%', marginTop: 25 }} />
    <Typography variant="body1">
      Once you have an account, ARCC Reactor needs three pieces of information.
    </Typography>
    <List>
      <ListItem>Account SSID</ListItem>
      <ListItem>Auth Token</ListItem>
      <ListItem>Twilio Phone Number</ListItem>
    </List>
    <Typography variant="body1">
      All three can be found in the Twilio console under Account Info.
    </Typography>
    <Typography variant="body1">
      It's important that you don't share this information with anyone else, as
      this is how Twilio will charge you for every text.
    </Typography>
    <Divider style={{ width: '100%', marginTop: 25 }} />
    <Typography variant="h5">
      Why is using Twilio cheaper than using a service like Text Recruit?
    </Typography>
    <Typography variant="body1">
      Twilio is a Software as a Service (SASS) platform. At this price point, it
      doesn't provide an interface to use for sending out messages, only the
      underlying software. ARCC Reactor is filling in that gap.
    </Typography>
    <Divider style={{ width: '100%', marginTop: 25 }} />
    <Typography variant="h5">What happens when someone replies?</Typography>
    <Typography variant="body1">
      You need to tell Twilio how to handle that by setting up a Studio Flow.
      This can be set up to forward the response to your personal phone or any
      other valid phone number, like a Google Voice account.
    </Typography>

    <Button
      target="_blank"
      href="https://support.twilio.com/hc/en-us/articles/115015962887-Forward-Incoming-SMS-Messages-with-Twilio-Studio"
    >
      Follow this guide to set up a flow.
    </Button>
  </Stack>
);

export default TwilioHelp;
