import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { Stack, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import HelpPanel from './help-panel';
import { TextHelp, TwilioHelp, CSVHelp, FAQHelp } from './help-pages';

interface HelpButtonProps {
  page: string;
  description: string;
}

const HelpButton = ({ page, description }: HelpButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const getPanelInstructions = (page: string): JSX.Element => {
    switch (page) {
      case 'text':
        return <TextHelp />;
      case 'twilio':
        return <TwilioHelp />;
      case 'csv':
        return <CSVHelp />;
      case 'faq':
        return <FAQHelp />;
      default:
        return (
          <Typography variant="body1">
            Need Help? Reach out to curtmorgan3@gamil.com
          </Typography>
        );
    }
  };

  return (
    <>
      <Stack direction="row">
        <Typography>
          <p>{description}</p>
        </Typography>
        <IconButton onClick={() => setOpen(true)}>
          <HelpIcon />
        </IconButton>
      </Stack>

      <HelpPanel open={open} onClose={() => setOpen(false)}>
        {getPanelInstructions(page)}
      </HelpPanel>
    </>
  );
};

export default HelpButton;
