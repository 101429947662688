import React from 'react';
import { Stack, Typography, Divider, List, ListItem } from '@mui/material';

const TextHelp = () => (
  <Stack
    justifyContent="space-around"
    alignItems="center"
    style={{ minHeight: '80vh', padding: 10 }}
  >
    <Typography variant="h4">Draft your text message</Typography>

    <Divider style={{ width: '100%' }} />

    <Typography variant="h5">
      This message will be sent to everyone in your csv file from your Twilio
      phone number.
    </Typography>

    <Typography variant="body1">
      To personalize each message, use the following variables. Make sure these
      are spelled correctly exactly! You mind find it helpful to copy & paste.
    </Typography>

    <List>
      <ListItem>$first-name$</ListItem>
      <ListItem>$last-name$</ListItem>
      <ListItem>$mos$</ListItem>
      <ListItem>$phone-number$</ListItem>
      <ListItem>$rank$</ListItem>
    </List>

    <Divider style={{ width: '100%' }} />

    <Typography variant="h5">Example</Typography>

    <Typography variant="body1">
      Good evening $rank$ $last-name$. My name is SSG Smith and I'm a career
      counselor in your area. We have a lot of $mos$ positions available. If
      you're interested, please shoot me a text back.
    </Typography>

    <Typography variant="body1">
      If you use a variable for which a row in your sheet doesn't have data
      available, it will be replaced with something sensible, like 'your MOS'.
    </Typography>
  </Stack>
);

export default TextHelp;
