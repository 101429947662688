import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

const Loader = () => (
  <Backdrop style={{ opacity: 0.6 }} open>
    <CircularProgress color="inherit" />
  </Backdrop>
);

export default Loader;
