/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

interface State {
  hasError: boolean;
  errorMessage: string;
}

interface Props {
  children: ReactNode;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorMessage: '',
  };

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Stack style={{ width: '100vw' }} alignItems="center" spacing={5}>
          <Typography variant="h4">Something went wrong</Typography>

          <Typography variant="body1">
            The ARCC Reactor was built and is maintained by one of our very own
            TPUs is his spare time, so errors are bound to happen.
          </Typography>

          <Typography variant="body1">
            Please send an email to curtmorgan3@gmail.com. Describe what you
            were doing when this error happened and include the message below in
            the email body:
          </Typography>

          <Typography variant="body1">{this.state.errorMessage}</Typography>
        </Stack>
      );
    }

    return this.props.children;
  }
}
