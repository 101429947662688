/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { DataEntry, Credentials } from './types';

export const cacheCredentials = (
  ssid: string | null,
  token: string | null,
  phoneNumber: string | null
) => {
  if (ssid) localStorage.setItem('arcc-reactor-ssid', ssid);
  if (token) localStorage.setItem('arcc-reactor-token', token);
  if (phoneNumber)
    localStorage.setItem('arcc-reactor-phoneNumber', phoneNumber);
};

export const getCachedCredentials = (): Credentials => {
  const ssid = localStorage.getItem('arcc-reactor-ssid');
  const token = localStorage.getItem('arcc-reactor-token');
  const phoneNumber = localStorage.getItem('arcc-reactor-phoneNumber');

  return {
    ssid,
    token,
    phoneNumber,
  };
};

export const readFile = (
  files: File[],
  setNewData: React.Dispatch<React.SetStateAction<DataEntry[] | null>>
): void => {
  if (!('TextDecoder' in window))
    alert(
      'This browser does not support decoding txt files. Please try the most recent version of Chrome, Firefox or Safari.'
    );

  if (!files) {
    setNewData([]);
    return;
  }

  const file = files[0];
  if (!file.name?.includes('csv')) {
    window.alert('File must use a .csv extension');
    return;
  }

  const reader = new FileReader();

  reader.onabort = () => console.error('file reading was aborted');
  reader.onerror = () => console.error('file reading has failed');

  reader.onload = () => {
    const binaryStr = reader.result as ArrayBuffer;

    if (!binaryStr) return;

    const enc = new TextDecoder('utf-8');
    const raw = enc.decode(binaryStr);

    let finalPass: DataEntry[] | Record<string, unknown> = {};

    if (raw) {
      let arr = raw.trim().split('\n');
      arr.shift();
      arr = arr.map((row) => row.trim()).filter((row) => row);

      finalPass = arr.map((row) => {
        const [firstName, lastName, grade, mos, phoneNumber] = row.split(',');

        return {
          firstName,
          lastName,
          grade,
          mos,
          phoneNumber,
        };
      });

      // Check if product list contains duplicate phone numbers
      const duplicate: string[] = [];
      const map: Record<string, boolean> = {};

      finalPass.forEach((entry) => {
        if (map[entry.phoneNumber]) {
          duplicate.push(entry.lastName);
        } else {
          map[entry.phoneNumber] = true;
        }
      });

      if (duplicate.length) {
        window.alert(
          `Duplicate phone numbers detected. ${duplicate.join(', ')}`
        );
        return;
      }

      setNewData(finalPass);
    }
  };

  reader.readAsArrayBuffer(file);
};
