import React from 'react';
import Drawer from '@mui/material/Drawer';

interface HelpPanelProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

const HelpPanel = ({ children, open, onClose }: HelpPanelProps) => {
  return (
    <Drawer open={open} onClose={onClose} anchor="right">
      <div style={{ width: '60vw' }}>{children}</div>
    </Drawer>
  );
};

export default HelpPanel;
