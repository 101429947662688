import React from 'react';
import { Stack, Typography, Divider, List, ListItem } from '@mui/material';

const CSVHelp = () => (
  <Stack
    justifyContent="space-around"
    alignItems="center"
    style={{ minHeight: '80vh', padding: 10 }}
  >
    <Typography variant="h4">Upload a CSV File</Typography>

    <Divider style={{ width: '100%' }} />

    <Typography variant="h5">What's a CSV File?</Typography>

    <Typography variant="body1">
      CSV Files are spreadsheets. You can export them from Excel or Google
      Sheets.
    </Typography>

    <Typography variant="body1">
      It should have the following columns in this order:
    </Typography>

    <List>
      <ListItem>first-name</ListItem>
      <ListItem>last-name</ListItem>
      <ListItem>grade</ListItem>
      <ListItem>mos</ListItem>
      <ListItem>phone-number</ListItem>
    </List>

    <Typography variant="body1">
      Every row must have a phone number value. The other values are optional.
    </Typography>
  </Stack>
);

export default CSVHelp;
