/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Typography, Button, Stack } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';
import { DataEntry } from '../types';
import HelpButton from './help-button';

interface DropzoneProps {
  readFile: (
    files: File[],
    setData: React.Dispatch<React.SetStateAction<DataEntry[] | null>>
  ) => void;
  setData: React.Dispatch<React.SetStateAction<DataEntry[]>>;
  handleUpload: (data: DataEntry[] | null) => void;
}

const Dropzone = ({ readFile, handleUpload, setData }: DropzoneProps) => {
  const [dzData, setDZData] = useState<DataEntry[] | null>(null);

  const onDrop = React.useCallback(
    (files: File[]) => {
      readFile(files, setDZData);
    },
    [readFile, setData]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleCancel = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setDZData(null);
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      style={{ height: 300, minWidth: '80vw' }}
    >
      <HelpButton page="csv" description="Upload CSV File" />
      {/* <Typography variant="h6">Upload CSV File</Typography> */}
      <Stack
        {...getRootProps()}
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <input {...getInputProps()} />
        {dzData ? (
          <Stack spacing={5}>
            <Typography variant="body1">{`${dzData.length || 0} soldier${
              dzData.length > 1 ? 's' : ''
            } ready to contact`}</Typography>
          </Stack>
        ) : isDragActive ? (
          <Typography variant="h6">Drop file here</Typography>
        ) : (
          <Stack justifyContent="center" alignItems="center">
            <Typography variant="h6">Drag & Drop or Click</Typography>
            <PublishIcon fontSize="large" />
          </Stack>
        )}
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-around"
        style={{ width: '40%' }}
      >
        <Button
          variant="contained"
          disabled={!dzData}
          onClick={() => handleUpload(dzData)}
        >
          Upload
        </Button>
        {dzData && (
          <Button variant="contained" color="error" onClick={handleCancel}>
            Cancel
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default Dropzone;
