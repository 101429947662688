/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useState, useEffect } from 'react';
import axios from 'axios';

import Dropzone from './components/dropzone';
import DataTable from './components/data-table';
import Loader from './components/loader';
import { Button, TextField, Typography, Stack } from '@mui/material';
import { cacheCredentials, getCachedCredentials, readFile } from './helpers';
import { DataEntry } from './types';
import HelpButton from './components/help-button';

const arccReactorUrl =
  'https://pfmq1492z0.execute-api.us-west-2.amazonaws.com/default/twilio-receive';

function App() {
  const [data, setData] = useState<DataEntry[]>([]);
  const [sid, setSid] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [outboundNumber, setOutboundNumber] = useState<string | null>(null);
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const { ssid, token, phoneNumber } = getCachedCredentials();

    if (ssid) setSid(ssid);
    if (token) setToken(token);
    if (phoneNumber) setOutboundNumber(phoneNumber);
  }, []);

  const handleCancel = () => {
    setData([]);
    setMessage('');
  };

  const handleSend = async () => {
    if (!data.length) {
      window.alert(
        'No receivers uploaded. Upload a CSV file with at least one row.'
      );
      return;
    }

    if (!message.length) {
      window.alert('You must first enter a message.');
      return;
    }

    if (!sid || !token || !outboundNumber) {
      window.alert('No Twilio credentials detected.');
      return;
    }

    const conf = window.confirm(
      `Are you sure you want to send a text to ${data.length} numbers?`
    );
    if (!conf) return;

    try {
      setLoading(true);
      // Send to Lambda Function
      const body = {
        twilioSid: sid,
        twilioAuthToken: token,
        twilioNumber: outboundNumber,
        data,
        message: message,
      };

      const res = await axios.post(arccReactorUrl, body);

      if (res.status === 200) {
        window.alert('Success!');
      }

      cacheCredentials(sid, token, outboundNumber);
    } catch (e: any) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      window.alert(`Failed to send. ${e?.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = (data: DataEntry[] | null) => {
    if (data) setData(data);
  };

  return (
    <Stack alignItems="center" style={{ width: '100%' }}>
      {loading && <Loader />}
      <Typography>
        <h2>ARCC Reactor</h2>
      </Typography>
      {!data.length && (
        <Dropzone
          readFile={readFile}
          setData={setData}
          handleUpload={handleUpload}
        />
      )}

      {!!data.length && (
        <Stack alignItems="center" style={{ width: '80%' }} spacing={4}>
          <HelpButton
            page="twilio"
            description="Enter your Twilio credentials"
          />

          <Stack
            direction="row"
            justifyContent="space-around"
            style={{ width: '100%' }}
          >
            <TextField
              style={{ backgroundColor: '#fff' }}
              placeholder="Twilio SID"
              value={sid}
              onChange={(e) => setSid(e.target.value)}
            />
            <TextField
              style={{ backgroundColor: '#fff' }}
              placeholder="Twilio Auth Token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
            <TextField
              style={{ backgroundColor: '#fff' }}
              placeholder="Twilio Phone Number"
              value={outboundNumber}
              onChange={(e) => setOutboundNumber(e.target.value)}
            />
          </Stack>
          <Stack style={{ width: '70%' }} alignItems="center" spacing={3}>
            <HelpButton page="text" description="Enter your text" />

            <Typography variant="body1" style={{ textAlign: 'center' }}>
              First time using ARCC Reactor? It's recommended that you test
              using only your personal phone number to get an idea of how it
              works.
            </Typography>

            <TextField
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              variant="filled"
              fullWidth
              placeholder="Enter your message"
              rows={10}
            />
          </Stack>

          <Stack
            direction="row"
            style={{ width: '40%' }}
            justifyContent="space-around"
          >
            <Button variant="contained" color="primary" onClick={handleSend}>
              Send
            </Button>
            <Button variant="contained" color="error" onClick={handleCancel}>
              Cancel
            </Button>
          </Stack>
          {data.length > 100 && (
            <Typography variant="body1" color="error">
              It's recommended that you batch no more than 100 messages at a
              time. You can still try this, but it's likely to timeout before
              completing.
            </Typography>
          )}

          <DataTable data={data} />
          <HelpButton page="faq" description="FAQ" />
        </Stack>
      )}
    </Stack>
  );
}

export default App;
