import React from 'react';
import { Stack, Typography, Divider } from '@mui/material';

const FAQHelp = () => (
  <Stack
    justifyContent="space-around"
    alignItems="center"
    style={{ minHeight: '80vh', padding: 10 }}
  >
    <Typography variant="h4">FAQ</Typography>

    <Divider style={{ width: '100%' }} />

    <Typography variant="h5">Who made ARCC Reactor?</Typography>

    <Typography variant="body1">
      SSG Morgan, a TPU in Area 1, 7th BN.
    </Typography>

    <Divider style={{ width: '100%' }} />

    <Typography variant="h5">
      Where can I send suggestions or complaints?
    </Typography>

    <Typography variant="body1">Email curtmorgan3@gmail.com</Typography>

    <Divider style={{ width: '100%' }} />

    <Typography variant="h5">Is ARCC Reactor secure?</Typography>

    <Typography variant="body1">
      There's absolutely no data persistence happening. No database, no server.
      Any information sent with ARCC Reactor is immediately destroyed as soon as
      you close your browser tab. Because of this, ARCC Reactor is exactly as
      secure as the network you're using it on. As a best practice, limit the
      data you're sending to name, MOS, rank and phone number.
    </Typography>

    <Divider style={{ width: '100%' }} />

    <Typography variant="h5">Is ARCC Reactor free?</Typography>

    <Typography variant="body1">
      Yes. It does require a Twilio account, which charges a marginal fee for
      every text sent and received. The use of ARCC Reactor itself is free and
      will continue to be so forever.
    </Typography>
  </Stack>
);

export default FAQHelp;
